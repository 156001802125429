<template>
  <div>
    <div class="header">
      <div class="header_top">
        当前位置：
        <span class="center">企业管理</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">合同配置</span>
      </div>
    </div>
    <div style="display: flex; align-items: center;justify-content: space-between; margin:10px 0;">
      <div style="display: flex; align-items: center;flex-wrap: wrap;">
        <div>
          <span class="seach_text">企业名称：</span>
          <el-select
            remote
            reserve-keyword
            :remote-method="query"
            @change="getCity"
            v-model="formInline.workEnterpriseCode"
            clearable
            filterable
            placeholder="请输入企业名称"
            size="mini"
            style="margin-right:10px"
            @remove-tag="clearableFun"
          >
            <el-option v-for="item in eList" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">企业区域：</span>
          <el-select
            v-model="formInline.workEnterpriseArea"
            filterable
            clearable
            size="mini"
            :placeholder="'请输入企业区域'"
          >
            <el-option-group
              v-for="group in areaSelectList"
              :key="group.provinceName"
              :label="group.provinceName"
            >
              <span style="color:red" slot="label">{{group.provinceName}}1</span>

              <el-option
                v-for="item in group.citys"
                :key="item.code"
                :label="item.cityName"
                :value="item.code"
              ></el-option>
            </el-option-group>
          </el-select>
        </div>
        <div style="margin:0 10px;">
          <span class="seach_text">合同名称：</span>
          <el-input
            placeholder="请输入合同名称"
            v-model="formInline.name"
            @input="query"
            style=" width: 150px"
            size="mini"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <div>
          <span class="seach_text">合同模板：</span>
          <el-select
            v-model="formInline.templateCode"
            clearable
            filterable
            size="mini"
            :placeholder="'请输入合同模板'"
            style="margin-right: 10px;"
          >
            <el-option
              v-for="(item,i) in contractTemplateList"
              :key="i"
              :label="item.name "
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">合同类型：</span>
          <el-select v-model="formInline.type" filterable size="mini" style="margin-right: 10px;">
            <el-option
              v-for="(item,i) in contractList"
              :key="i"
              :label="item.label "
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          size="small"
          style="margin:10px;border-radius: 5px;"
          type="primary"
          @click="seach"
        >查询</el-button>
      </div>
      <div>
        <el-button
          size="small"
          style="margin:0 10px;border-radius: 5px;"
          type="primary"
          @click="addEnterpriseContract"
        >新建合同配置</el-button>
      </div>
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column label="用工企业名称 " width="280" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.enterprise">{{ scope.row.enterprise.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="企业区域" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.area">{{scope.row.area.provinceName}}-{{scope.row.area.cityName}}</span>
        </template>
      </el-table-column>

      <el-table-column label="合同名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="合同模板" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.template">{{scope.row.template.name}}</span>
        </template>
      </el-table-column>

      <el-table-column label="合同类型" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.template.type==='1'">入职合同</span>
          <span v-if="scope.row.template.type==='2'">离职合同</span>
          <span v-if="scope.row.template.type==='3'">用工合同</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="shuxian">
            <span class="clickColor" @click="goEdit(scope.row)">编辑</span>
            <el-divider direction="vertical"></el-divider>
          </div>
          <!-- <div class="shuxian">
            <el-popover
              placement="top"
              width="400"
              trigger="manual"
              :value="visible"
              :ref="'popover-' + scope.row.id"
            >
              <div class="del-pop">
                <img src="../../../assets/del.png" alt />
              </div>
              <div class="del-title">删除企业区域信息</div>
              <div class="del-sumtitle">确认删除该条企业区域信息？</div>
              <div class="del-btn">
                <el-button type="primary" @click="del(scope.row)" plain>确认</el-button>
                <el-button type="info" @click="doCancel(scope.row.id)" plain>取消</el-button>
              </div>
              <el-button
                slot="reference"
                style="color:red"
                type="text"
                @click="pOpen(scope.row.id)"
              >删除</el-button>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
          </div>-->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- <addDialog
      :dialogVisible.sync="addDialogVisible"
      :code.sync="code"
      :title.sync="title"
      @func="addClose()"
    ></addDialog>-->
  </div>
</template>

<script>
import { api } from '/src/api/base';
export const request = api('/enterprise');

// 合同模板
export const workEnterpriseList = api()('work_enterprise.list.json');
export const contractTemplateEnterpriseList = api()(
  'contractTemplate.enterprise.list.json'
);
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');
export const ContractTemplateList = api()(
  'contractTemplate.list.json'
);
export default {
  components: {
    // addDialog: () => import('./add'),
  },
  data () {
    return {
      visible: false,
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      code: '',
      formInline: {
        workEnterpriseCode: '',
        workEnterpriseArea: '',
        templateCode: '',
        name: ''
      },
      list: [],
      eList: [],
      authenticateState: '',
      addDialogVisible: false,
      title: '',
      // "0"：未认证 "1"：打款中  "2"：打款成功  "3"：认证成功 "4"认证失败


      areaSelectList: [{
        label: '全部',
        value: '',
      },
      {
        label: '北京',
        value: '0',
      },
      {
        label: '北京',
        value: '3',
      }],
      contractList: [{
        label: '全部',
        value: '',
      },
      {
        label: '入职合同',
        value: '1',
      },
      {
        label: '离职合同',
        value: '0',
      },
      {
        label: '用工合同',
        value: '2',
      }],
      contractTemplateList: []
    };
  },
  computed: {
    classVal: function () {
      return {
        'font-color-warning': true,
      };
    },
  },

  created () {
    this.loadData();
    this.getWorkEnterpriseList();
    this.getContractTemplateList()

  },
  methods: {
    pOpen (id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel (id) {
      this.pClose(id);
    },
    pClose (id) {
      this.$refs['popover-' + id].doClose();
    },
    keywords () {
      this.pageNumber = 1;
      this.loadData();
    },
    getContractTemplateList () {
      const params = {

      };
      ContractTemplateList(params).then(res => {
        this.contractTemplateList = res.list

        console.log(res);
      });
    },
    addEnterpriseContract () {

      this.$router.push({
        path: '/system/enterpriseContract/Add',
        query: {
          title: '新建合同配置',
        },
      });
    },
    clearableFun () {
      console.log(111);
    },
    goEdit (row) {

      this.$router.push({
        path: '/system/enterpriseContract/Add',
        query: {
          code: row.code,
          title: '编辑合同配置',
        },
      });

    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    getWorkEnterpriseList () {

      const params = {
        pageNumber: 1,
          pageSize: 1000 
      };
      workEnterpriseList(params).then(res => {
        this.eList = res.list
      });
    },
    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res

        });
      }
      else {
        this.areaSelectList = []
        this.formInline.workEnterpriseArea = ''
      }

    },
    loadData () {
      const params = {
        ...this.formInline,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      contractTemplateEnterpriseList(params).then(res => {
        this.list = res.list
        this.total = res.total

      });
    },

    del (obj) {
      request('enterprise.del.json')({ code: obj.code }).then(() => {
        this.$message.success('内容成功删除！');
        this.doCancel(obj.id);
        this.loadData();
      });
    },
    addClose () {
      this.loadData();
    },
    seach () {
      this.pageNumber = 1;
      this.loadData();
    },
    query () {
      this.pageNumber = 1;
      this.loadData();
    },



  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  border: none;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
  .header_top {
    display: flex;
    align-items: center;
    img:nth-child(1) {
      width: 20px;
      height: 17px;
    }
    img:nth-child(2) {
      width: 9px;
      height: 10px;
    }
    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.el-form-item {
  margin-top: 12px !important;
  margin-bottom: 10px;
}
.table_border {
  margin: 20px 0;
  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }
  .table_demo {
    padding: 20px 0;
    font-size: 10px;
  }
}
.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}
.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}
</style>
